<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        small
      >
        mdi-file-eye
      </v-icon>
    </template>
    <v-card>
      <v-toolbar
        dark
        color="primary"
        elevation="0"
        dense
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Preview {{ domain }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="handleOpenExternal"
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
          <v-btn
            dark
            text
            @click="dialog = false"
          >
            {{$t('common.close', locale)}}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-layout
        wrap
        v-if="dialog"
      >
        <v-flex xs2 style="padding: 5px;" class="dtouchMobilePreview">
          <v-btn
            @click="handleRefresh('dtouch')"
            color="green"
            block
            small
            dark
            style="margin-bottom: 10px;"
          >
            refresh
          </v-btn>
          <preview
            :url="prepareDtouch(LMUDtouch)"
            :locale="locale"
            :size="{
              w: 375,
              h: 799,
            }"
            className="dtouchMobilePreview"
            name="if-dtouch"
          />
        </v-flex>
        <v-flex xs3 style="padding: 5px;" class="dtouchTotemPreview">
          <v-btn
            @click="handleRefresh('totem')"
            color="green"
            block
            small
            dark
            style="margin-bottom: 10px;"
          >
            refresh
          </v-btn>
          <preview
            :url="prepareTotem(LMUTotem)"
            :locale="locale"
            :size="{
              w: 1080,
              h: 1920,
            }"
            className="dtouchTotemPreview"
            name="if-totem"
          />
        </v-flex>
        <v-flex xs7 style="padding: 5px;" class="dtouchDesktopPreview">
          <v-btn
            @click="handleRefresh('desktop')"
            color="green"
            block
            small
            dark
            style="margin-bottom: 10px;"
          >
            refresh
          </v-btn>
          <preview
            :url="prepareDesktop(LMUDesktop)"
            :locale="locale"
            :size="{
              w: 1920,
              h: 1080,
            }"
            className="dtouchDesktopPreview"
            name="if-desktop"
          />
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>
<script>
//import api from '@/services/api'
import Preview from './Preview'
export default {
  components: {
    Preview,
  },
  props: {
    domain: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: true, 
    dialog: false,
    LMUDtouch: null,
    LMUTotem: null,
    LMUDesktop: null,
  }),
  computed: {
    port () {
      return '8080'
    },
    h () {
      return process.env.NODE_ENV === 'development' ? `http` : 'https'
    },
    b () {
      return process.env.NODE_ENV === 'development' ? `local:${this.port}` : 'es'
    },
  },
  mounted () {
  },
  methods: {
    handleRefresh (type) {
      for (const i of document.getElementsByName(`if-${type}`)){
        i.src = ''
        setTimeout(() => {
          i.src = type === 'dtouch' ? this.prepareDtouch() : type === 'totem' ? this.prepareTotem() : this.prepareDesktop()
        }, 1000)
      }
    },
    prepareDtouch () {
      const lmu = new Date().toISOString()
      return `${this.h}://${this.domain}.dtouch.${this.b}/#/?fm=true&lmu=${lmu}`
    },
    prepareTotem () {
      const lmu = new Date().toISOString()
      return `${this.h}://${this.domain}.dtouch.${this.b}/#/totem?fullscreen=true&lmu=${lmu}`
    },
    prepareDesktop () {
      const lmu = new Date().toISOString()
      return `${this.h}://${this.domain}.dtouch.${this.b}/#/?lmu=${lmu}`
    },
    handleOpenExternal () {
      const url = `${window.location.origin}/#/dtouch-preview/${this.domain}`
      window.open(url)
    },
  },
}
</script>
